import React, {FC, useEffect, useRef, useState} from 'react';
import { animated, useTransition } from '@react-spring/web';
import useWebApp from "../../hooks/useWebApp";
import {WebApp} from "../../types/twa-types";
import {ClickerContainer, ClickerSparkText} from './Clicker.Styles';
import useWebSocket from "../../hooks/useWebSocket";

export const Clicker: FC = () => {
  const webApp: WebApp = useWebApp();
  const { sendTap, wallet: {availableEnergy, tapThreshold}} = useWebSocket();

  const [isTouched, setIsTouched] = useState<boolean>(false);
  const [sparks, setSparks] = useState<any[]>([]);
  const timer = useRef<number>();

  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current)
    }

    if (sparks.length) {
      timer.current = window.setTimeout(() => {
        removeOldSparks();
      }, 1000)
    }

    return () => {
      clearTimeout(timer.current)
    }
  // @ts-ignore
  }, [sparks.length])

  const transition = useTransition(sparks, {
    from: {
      opacity: 1,
      marginTop: 0
    },
    enter: { opacity: 0, marginTop: -100},
    trail: 150
  });

  const removeOldSparks = () => {
    const currentTime: number = Date.now();
    const newSparks = [...sparks].filter((spark: any) => {
      return currentTime < (spark.timeStamp + 1000)
    });

    setSparks(newSparks);

    if (newSparks.length) {
      timer.current = window.setTimeout(() => {
        removeOldSparks();
      }, 1000)
    }
  };

  const onTouchEvent = (event: any) => {
    const touch = event.changedTouches[0];
    clickOnClicker({clientX: touch.clientX, clientY: touch.clientY});
  };

  const onTouchStartEvent = (event: any) => {
    event.preventDefault();
    if (!isTouched) {
      setIsTouched(true);
    }
  };

  const onClickEvent = (event: any) => {
    event.preventDefault();
    if (isTouched) return false;
    clickOnClicker({clientX: event.clientX, clientY: event.clientY});
  };

  const clickOnClicker = ({clientX, clientY}: {clientX: number, clientY: number}) => {
    if (availableEnergy >= tapThreshold) {
      webApp.HapticFeedback?.impactOccurred("rigid");
      webApp.HapticFeedback?.impactOccurred("heavy");
      sendTap();

      const newItem = {
        text: `+${tapThreshold}`,
        x: clientX,
        y: clientY,
        timeStamp: Date.now()
      };

      setSparks((prevSparks) => ([...prevSparks, newItem]));
    }
  };

  return (
    <ClickerContainer>
      <button
        type="button"
        className="clicker"
        onTouchEnd={(event: any) => onTouchEvent(event)}
        onTouchStart={(event: any) => onTouchStartEvent(event)}
        onClick={(event: any) => onClickEvent(event)}
      >
        <svg className="clicker-img" width="372" height="372" viewBox="0 0 372 372" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_bd_2001_551)">
            <circle cx="186" cy="182" r="140" fill="url(#paint0_linear_2001_551)" fillOpacity="0.51"
                    shapeRendering="crispEdges"/>
            <circle cx="186" cy="182" r="140" stroke="#3E3E3F" shapeRendering="crispEdges"/>
          </g>
          <g filter="url(#filter1_b_2001_551)">
            <circle cx="186" cy="182" r="120" fill="black" fillOpacity="0.8"/>
            <circle cx="186" cy="182" r="119.5" stroke="#3E3E3F"/>
          </g>
          <path
            d="M186.009 224.892C162.36 224.892 143.112 205.646 143.112 182C143.112 158.354 162.36 139.108 186.009 139.108V113C147.961 113 117 143.957 117 182C117 220.043 147.961 251 186.009 251C224.058 251 254.534 220.509 255 182.858H228.888C228.422 206.113 209.379 224.892 186.009 224.892Z"
            fill="url(#paint1_linear_2001_551)" fillOpacity="0.14"/>
          <path
            d="M212.811 113H186.028C186.494 151.043 217.082 181.925 255 182.876V156.078C231.854 155.146 213.277 136.274 212.811 113Z"
            fill="url(#paint2_linear_2001_551)" fillOpacity="0.14"/>
          <path
            d="M185.991 202.551C197.229 202.551 206.339 193.442 206.339 182.205C206.339 170.968 197.229 161.859 185.991 161.859C174.753 161.859 165.643 170.968 165.643 182.205C165.643 193.442 174.753 202.551 185.991 202.551Z"
            fill="url(#paint3_linear_2001_551)" fillOpacity="0.14"/>
          <defs>
            <filter id="filter0_bd_2001_551" x="-74.5" y="-78.5" width="521" height="521" filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="60"/>
              <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2001_551"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                             result="hardAlpha"/>
              <feOffset dy="4"/>
              <feGaussianBlur stdDeviation="22.5"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
              <feBlend mode="normal" in2="effect1_backgroundBlur_2001_551" result="effect2_dropShadow_2001_551"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2001_551" result="shape"/>
            </filter>
            <filter id="filter1_b_2001_551" x="62" y="58" width="248" height="248" filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="2"/>
              <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2001_551"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2001_551" result="shape"/>
            </filter>
            <linearGradient id="paint0_linear_2001_551" x1="413.451" y1="22.2817" x2="80.4244" y2="353.908"
                            gradientUnits="userSpaceOnUse">
              <stop stopColor="white"/>
              <stop offset="1" stopColor="white" stopOpacity="0.23"/>
            </linearGradient>
            <linearGradient id="paint1_linear_2001_551" x1="186" y1="113" x2="186" y2="251"
                            gradientUnits="userSpaceOnUse">
              <stop stopColor="#C0C1DC" stopOpacity="0.88"/>
              <stop offset="1" stopColor="#CBCCE4"/>
            </linearGradient>
            <linearGradient id="paint2_linear_2001_551" x1="220.514" y1="113" x2="220.514" y2="182.876"
                            gradientUnits="userSpaceOnUse">
              <stop stopColor="#C0C1DC" stopOpacity="0.88"/>
              <stop offset="1" stopColor="#CBCCE4"/>
            </linearGradient>
            <linearGradient id="paint3_linear_2001_551" x1="185.991" y1="161.859" x2="185.991" y2="202.551"
                            gradientUnits="userSpaceOnUse">
              <stop stopColor="#C0C1DC" stopOpacity="0.88"/>
              <stop offset="1" stopColor="#CBCCE4"/>
            </linearGradient>
          </defs>
        </svg>

        <div
          aria-hidden
          className="clicker-sparks"
        >
          {transition(
            (style, item) =>
              item ? (
                <animated.div
                  style={{
                    ...style,
                    position: "absolute",
                    top: item.y,
                    left: item.x,
                    borderRadius: "5px"
                  }}
                >
                  <ClickerSparkText>{item.text}</ClickerSparkText>
                </animated.div>
              ) : null
          )}
        </div>
      </button>
    </ClickerContainer>
  );
};
export default Clicker;
