import React, { FC } from 'react';
import Clicker from "../../components/Clicker/Clicker";
import {Balance, Energy} from "./Home.Styles";
import {useTranslation} from "react-i18next";
import useWebSocket from "../../hooks/useWebSocket";
const HomeApp: FC = () => {
  const { t } = useTranslation();
  const {
    wallet: {
      points,
      energyThreshold,
      availableEnergy
    }
  } = useWebSocket();

  return (
    <>
      <Balance>
        <span className="balance">{points}</span>
        <span className="balance-text">{t('home.balance')}</span>
      </Balance>
      <Clicker/>
      <Energy>
        <span className="energy">⚡ {t('home.energy.title')} {availableEnergy}/{energyThreshold}</span>
      </Energy>
    </>
  );
};

export default HomeApp;
