import styled from 'styled-components';

export const ClickerContainer: any = styled.div`
  .clicker {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    cursor: pointer;
    background: transparent;
    border: 0;
    
    &-img {
      width: 100%;
      height: auto;
      transform: none;
    }
    
    &-sparks {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
    }
    
    &-spark {
      position: absolute;
      opacity: 0;
      top: 50%;
      left: 50%;
      fill: #fff;
    }
  }
`;

export const ClickerSpark: any = styled.div<{ x?: number; y?: number; }>`
  position: absolute;
  top: calc(${props => props.y ? `${props.y}px` : '50%'});
  left: calc(${props => props.x ? `${props.x}px` : '50%'});
  font-size: 11vmin;
  font-weight: 900;
  line-height: 11vmin;
  letter-spacing: 0.4000000059604645px;
  color: #EAEBF5;
  opacity: 1;
`;

export const ClickerSparkText: any = styled.span`
  display: block;
  margin-top: -5.5vmin;
  margin-left: -6vmin;
  font-size: 11vmin;
  font-weight: 900;
  line-height: 11vmin;
  letter-spacing: 0.4000000059604645px;
  color: #EAEBF5;
`;
