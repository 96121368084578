import React from 'react';
import {NavLink, useLocation} from 'react-router-dom';

import {PATHS} from "../../const/paths.constants";
import {MenuContainer} from "./Menu.Styles";
import {useTranslation} from "react-i18next";

const Menu: React.FC = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  return (
    <MenuContainer>
      <ul className="menu">
        <li className={`menu-item ${pathname === PATHS.HOME ? '-active' : ''}`}>
          <NavLink
            tabIndex={-1}
            to={PATHS.HOME}
            className='menu-item__link'
          >
            <span className="menu-item__icon">✨</span>
            <div className="menu-item__text_wrap">
              <span className="menu-item__text">{t('menu.home')}</span>
            </div>
          </NavLink>
        </li>
        <li className={`menu-item ${pathname === PATHS.ENERGY ? '-active' : ''}`}>
          <NavLink
            tabIndex={-1}
            to={PATHS.ENERGY}
            className='menu-item__link'
          >
            <span className="menu-item__icon">⚡</span>
            <div className="menu-item__text_wrap">
              <span className="menu-item__text">{t('menu.energy')}</span>
            </div>
          </NavLink>
        </li>
        <li className={`menu-item ${pathname === PATHS.REF ? '-active' : ''}`}>
          <NavLink
            tabIndex={-1}
            to={PATHS.REF}
            className='menu-item__link'
          >
            <span className="menu-item__icon">💃</span>
            <div className="menu-item__text_wrap">
              <span className="menu-item__text">{t('menu.friends')}</span>
            </div>
          </NavLink>
        </li>
        <li className={`menu-item ${pathname === PATHS.EARN ? '-active' : ''}`}>
          <NavLink
            tabIndex={-1}
            to={PATHS.EARN}
            className='menu-item__link'
          >
            <span className="menu-item__icon">💎</span>
            <div className="menu-item__text_wrap">
              <span className="menu-item__text">{t('menu.tasks')}</span>
            </div>
          </NavLink>
        </li>
      </ul>
    </MenuContainer>
  );
};

export default Menu;
