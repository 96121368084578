import styled from 'styled-components';

export const MenuContainer: any = styled.div`
  display: flex;
  margin-top: auto;
  flex-direction: column;
  
  .menu {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    background: linear-gradient(225.12deg, rgba(255, 255, 255, 0.14) -19.16%, rgba(255, 255, 255, 0.0322) 99.53%);
    box-shadow: 0 4px 45px 0 #0000001A;
    border-top: 1px solid #ACACB64D;
    height: 19vmin;

    &-item {
      display: flex;
      flex: 1;
      align-items: center;
      
      &__link {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
      }
      
      &__icon {
        font-size: 5vmin;
        margin-bottom: 1vmin;
      }
      
      &__text {
        font-size: 3.2vmin;
        font-weight: 400;
        line-height: 3.2vmin;
        letter-spacing: -0.07999999821186066px;
        color: #43434A;
        
        &_wrap {
          display: flex;
          flex-direction: column;
        }
      }
      
      &.-active {
        .menu {
          &-item {
            &__text {
              color: #ffffff;
            }
          }
        }
      }
    }
  }
`;
